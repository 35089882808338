<script setup>
import { onMounted, ref, inject } from "vue";
import moment from "moment";
import useApi from "@/service/api/history";
const { getData_registrations } = useApi();
const store = inject("store");
const router = inject("router");

const datas = ref([]);
let isLoading = ref(true);
let color = ref(process.env.VUE_APP_COLOR);

onMounted(() => {
  if (!store.getters.getAccessToken.accesstoken) {
    router.push({ name: "Home" });
  }
  getData_registrations().then((res) => {
    datas.value = res.data.data;
    isLoading.value = false;
  });
});

function dateThai(date) {
  let y = moment(date).format("yyy");
  let year = parseInt(y) + 543;
  let march = moment(date).locale("th");

  return march.format("dddที่ D MMMM พ.ศ. " + year + " เวลา HH : mm : ss");
}

function dateThai1(date) {
  let y = moment(date).format("yyy");
  let year = parseInt(y) + 543;
  let march = moment(date).locale("th");

  return march.format("ddd D MMM พ.ศ. " + year);
}
</script>

<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <template v-if="datas.length > 0">
      <div class="mb-3">
        <div class="card-body mt-3 white" v-for="(data, index) in datas" :key="index">
          <div class="history">
            <div class="history-header">
              <div class="history-title text-root">
                {{ data.department_name }}
              </div>
              <div
                class="history-status"
                v-if="data.status === 'ยืนยันแล้ว'"
                style="color: blue;"
              >
                {{ data.status }}
              </div>
              <div
                class="history-status"
                v-else-if="data.status === 'เสร็จสิ้น'"
                style="color: green"
              >
                {{ data.status }}
              </div>
              <div class="history-status" v-else style="color: red">
                {{ data.status }}
              </div>
            </div>
            <div class="history-body">
              <div class="history-detail">
                ({{ dateThai1(data.dateST) }} , {{ data.dateC }})
              </div>
              <div class="history-botton">
                <router-link
                  :to="{
                    name: 'History_detail_registration',
                    params: { id: data.id },
                  }"
                >
                  <button type="button" class="btn see-more">ดูรายละเอียด</button>
                </router-link>
              </div>
            </div>

            <div class="history-footer text-root">
              วันที่จอง : {{ dateThai(data.created_at) }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="card-body mt-3 white">
        <div class="history-empty">
          <div class="history-empty-text">ยังไม่มีข้อมูล</div>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
.card-body {
  padding: 0;
}

.title {
  background-color: white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 10px;
}
.title-text {
  font-size: 12px;
  margin-right: 5px;
}
.icon-logo {
  height: 27px;
  background-color: white;
  margin-left: 10px;
  border-radius: 50%;
  margin-top: -10px;
}
.body-text {
  height: 16rem;
  position: relative;
}
.hide-scrollbar {
  position: relative;
  height: 100%;
  overflow-y: hidden;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.scrollbar {
  height: 0.25rem;
  border-radius: 0.0625rem;
  position: absolute;
  bottom: 0.3125rem;
  left: calc(50% - 0.75rem);
  width: 1.5rem;
  background: var(--color);
}
.scrollbar-st {
  background: #ee4d2d;
  width: 10.801px;
  transform: translateX(0px);
}
.appeal-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
}

.appeal-item {
  width: 25%;
  padding: 0 0.0625rem;
  text-align: center;
  height: 7rem;
}
.appeal-image {
  position: relative;
}
.appeal-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.history-image {
  position: relative;
}
.history-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.appeal-text {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 2.625rem;
  font-size: 12px;
  line-height: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  word-wrap: break-word;
  overflow: hidden;
  white-space: normal;
  height: 30px;
}
.history-header {
  padding: 10px;
  display: flex;
}
.history-title {
  width: 80%;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;
}
.history-status {
  width: 20%;
  font-size: 12px;
  text-align: end;
}
.history-body {
  padding: 10px;
  display: flex;
  position: relative;
}
.history-detail {
  margin-left: 10px;
}
.history-footer {
  font-size: 12px;
  padding: 10px;
  margin-left: 5px;
}
.history-botton {
  position: absolute;
  right: 10px;
  bottom: 0;
}
.see-more {
  background-color: var(--color) !important;
  border-color: var(--color) !important;
  color: white;
}
.history-empty {
  position: relative;
  height: 157.5px;
}
.history-empty-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -40px;
  opacity: 0.8;
}
</style>
