<script setup>
import { onMounted, ref, inject } from "vue";
import moment from "moment";
import useApi from "@/service/api/history";
import { useRoute } from "vue-router";
import useSweetalert from "@/service/sweetalert2";
import { Field } from "vee-validate";


const route = useRoute();
const id = ref(route.params.id);
const { getData_registration, cancel_Queue_registration } = useApi();
const store = inject("store");
const router = inject("router");
const { SmessIn, SconfirmNc } = useSweetalert();
const data = ref("");
let isLoading = ref(true);
let color = ref(process.env.VUE_APP_COLOR);

onMounted(() => {
  if (!store.getters.getAccessToken.accesstoken) {
    router.push({ name: "Home" });
  }
  getData_registration(id.value).then((res) => {
    data.value = res.data.data;
    isLoading.value = false;
  });
});

const back = () => {
  router.push({ name: "History" });
};

const cancel = () => {
  SmessIn().then((res) => {
    if (res.isConfirmed) {
      if (!res.value) {
        SconfirmNc("กรุณากรอกเหตุผลการยกเลิกคิว", "error", "ตกลง");
      } else {
        isLoading.value = true;
        let values = {
          id: id.value,
          id_line: store.getters.getAccessToken.accesstoken,
          detail: res.value,
        };

        cancel_Queue_registration(values).then((result) => {
          isLoading.value = false;
          if (result.data.status === "success") {
            SconfirmNc("ยกเลิกคิวสำเร็จ", "success", "ตกลง").then(() => {
              isLoading.value = true;
              getData_registration(result.data.data.id).then((res) => {
                data.value = res.data.data;
                isLoading.value = false;
              });
            });
          }
        });
      }
    }
  });
};

function dateThai(date) {
  let y = moment(date).format("yyy");
  let year = parseInt(y) + 543;
  let march = moment(date).locale("th");

  return march.format("dddที่ D MMMM พ.ศ. " + year + " เวลา HH : mm : ss");
}

function dateThai1(date) {
  let y = moment(date).format("yyy");
  let year = parseInt(y) + 543;
  let march = moment(date).locale("th");

  return march.format("ddd D MMM พ.ศ. " + year);
}
</script>
<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>

    <div class="row">
      <div class="col-12">
        <div class="card concard card-shadow">
          <Header></Header>

          <div class="text-start mn-profile">
            <span class="text-root mini-h"> จองคิวการให้บริการงานทะเบียน </span>
          </div>
          <div class="card-body mt-2 mb-47 white">
            วันที่จอง : {{ dateThai(data.created_at) }}

            <!--begin::Row-->
            <div class="row mb-7 mt-2">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">แผนก</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">
                  {{ data.department_name }}
                </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7 mt-2">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">วันที่</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">
                  {{ dateThai1(data.dateST) }}
                </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <!--begin::Row-->
            <div class="row mb-7 mt-2">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">ช่วงเวลา</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">
                  {{ data.dateC }}
                </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <!--begin::Row-->
            <div class="row mb-7 mt-2">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">สถานะ</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span
                  class="fw-bolder fs-6"
                  v-if="data.status === 'ยืนยันแล้ว'"
                  style="color: blue"
                >
                  {{ data.status }}
                </span>
                <span
                  class="fw-bolder fs-6"
                  v-else-if="data.status === 'เสร็จสิ้น'"
                  style="color: green"
                >
                  {{ data.status }}
                </span>
                <span class="fw-bolder fs-6" v-else style="color: red">
                  {{ data.status }}
                </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <!--begin::Row-->
            <div class="row mb-7 mt-2" v-if="data.doc && data.status === 'ยืนยันแล้ว'">
              <!--begin::Label-->
              <label class="col-12 fw-bold text-muted"
                >รายละเอียดเอกสารที่ต้องเตรียม</label
              >
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-12">
                <Field
                  name="doc"
                  class="form-control form-control-lg form-control-solid"
                  style="font-size: 14px"
                  v-model="data.doc"
                  as="textarea"
                  rows="5"
                  disabled="disabled"
                />
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7 mt-2" v-if="data.status === 'ยืนยันแล้ว'">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted"></label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <button type="button" @click="cancel" class="btn btn-danger">
                  ยกเลิกคิว
                </button>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7 mt-3" v-if="data.detail">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted">รายละเอียด</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6 text-dark">
                  {{ data.detail }}
                </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->

            <!--begin::Row-->
            <div class="row mb-7 mt-3" v-if="data.cancel_by">
              <!--begin::Label-->
              <label class="col-5 fw-bold text-muted"></label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-7">
                <span class="fw-bolder fs-6" style="color: red">
                  {{ data.cancel_by }}
                </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <button type="button" class="button" @click="back">ย้อนกลับ</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.title {
  background-color: white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 10px;
}
.title-text {
  font-size: 12px;
  margin-right: 5px;
}
.icon-logo {
  height: 27px;
  background-color: white;
  margin-left: 10px;
  border-radius: 50%;
  margin-top: -10px;
}
.body-text {
  height: 16rem;
  position: relative;
}
.hide-scrollbar {
  position: relative;
  height: 100%;
  overflow-y: hidden;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.scrollbar {
  height: 0.25rem;
  border-radius: 0.0625rem;
  position: absolute;
  bottom: 0.3125rem;
  left: calc(50% - 0.75rem);
  width: 1.5rem;
  background: var(--color);
}
.scrollbar-st {
  background: #ee4d2d;
  width: 10.801px;
  transform: translateX(0px);
}
.appeal-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
}

.appeal-item {
  width: 25%;
  padding: 0 0.0625rem;
  text-align: center;
  height: 7rem;
}
.appeal-image {
  position: relative;
}
.appeal-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.history-image {
  position: relative;
}
.history-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.appeal-text {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 2.625rem;
  font-size: 12px;
  line-height: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  word-wrap: break-word;
  overflow: hidden;
  white-space: normal;
  height: 30px;
}
.history-header {
  padding: 10px;
  display: flex;
}
.history-title {
  width: 80%;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;
}
.history-status {
  width: 20%;
  font-size: 12px;
  text-align: end;
}
.history-body {
  padding: 10px;
  display: flex;
  position: relative;
}
.history-detail {
  margin-left: 10px;
}
.history-footer {
  font-size: 12px;
  padding: 10px;
  margin-left: 5px;
}
.history-botton {
  position: absolute;
  right: 10px;
  bottom: 0;
}
.see-more {
  background-color: var(--color) !important;
  border-color: var(--color) !important;
  color: white;
}
.history-empty {
  position: relative;
  height: 157.5px;
}
.history-empty-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -40px;
  opacity: 0.8;
}
</style>
