<script setup>
import { onMounted, ref, inject } from "vue";

import Tabs from "@/components/Tabs";
import Tab from "@/components/Tab";
import TabPanels from "@/components/TabPanels";
import TabPanel from "@/components/TabPanel";

import Id_card from "@/components/Id_card";
import Registration from "@/components/Registration";

import Id_card_finish from "@/components/Id_card_finish";
import Registration_finish from "@/components/Registration_finish";


const store = inject("store");
const router = inject("router");


const active = ref(0);
const active_s = ref(0);


onMounted(() => {
  if (!store.getters.getAccessToken.accesstoken) {
    router.push({ name: "Home" });
  }
});

const back = () => {
  router.push({ name: "Profile" });
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow">
        <Header></Header>

        <div class="text-start mn-profile">
          <span class="text-root mini-h"> ประวัติการจองคิว </span>
        </div>
        <div class="card-body mt-2 mb-47">
          <span
            class="text-root mt-3"
            style="margin-left: 10px; font-weight: bold; text-decoration: underline;color: blue;"
          >
            คิวที่รอดำเนินการ
          </span>
          <tabs v-model="active">
            <tab>ทำบัตรประชาชน</tab>
            <tab>การให้บริการงานทะเบียน</tab>
          </tabs>

          <tab-panels v-model="active">
            <tab-panel> <Id_card></Id_card></tab-panel>
            <tab-panel><Registration></Registration> </tab-panel>
          </tab-panels>


          <span
            class="text-root mt-3"
            style="margin-left: 10px; font-weight: bold; text-decoration: underline;color: red;"
          >
            คิวที่ดำเนินการแล้ว
          </span>

          <tabs v-model="active_s">
            <tab>ทำบัตรประชาชน</tab>
            <tab>การให้บริการงานทะเบียน</tab>
          </tabs>

          <tab-panels v-model="active_s">
            <tab-panel> <Id_card_finish></Id_card_finish></tab-panel>
            <tab-panel><Registration_finish></Registration_finish> </tab-panel>
          </tab-panels>
        </div>
        <div class="card-footer white">
          <div class="d-flex justify-center align-center">
            <button type="button" class="button" @click="back">ย้อนกลับ</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-body {
  padding: 0;
}


</style>
