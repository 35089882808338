import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";

import Profile from "@/views/profile/Profile.vue";

import Queue from "@/views/queue/Index.vue";
import Queue_registration from "@/views/queue_registration/Index.vue";

import History from "@/views/history/Index.vue";
import History_detail from "@/views/history/Show.vue";
import History_detail_registration from "@/views/history/Show_registration.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },

  {
    path: "/queue/:id",
    name: "Queue",
    component: Queue,
  },
  {
    path: "/queue_registration",
    name: "Queue_registration",
    component: Queue_registration,
  },
  {
    path: "/history",
    name: "History",
    component: History,
  },
  {
    path: "/history/:id",
    name: "History_detail",
    component: History_detail,
  },
  {
    path: "/history_registration/:id",
    name: "History_detail_registration",
    component: History_detail_registration,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
