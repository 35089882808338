import ApiService from "@/service/ApiService";

export default function useApi() {


  const getDatas = async () => {
    return await ApiService.get("get_id_card_queue_history");
  };
  const getData = async (id) => {
    return await ApiService.get("get_id_card_queue_history/" +id);
  };
  const getData_finish = async () => {
    return await ApiService.get("get_id_card_queue_finish_history");
  };

  const getData_registrations = async () => {
    return await ApiService.get("get_registration_queue_history");
  };

  const getData_registration = async (id) => {
    return await ApiService.get("get_registration_queue_history/" +id);
  };

  const getData_registration_finish = async () => {
    return await ApiService.get("get_registration_queue_finish_history");
  };


  const cancel_Queue = async (data) => {
    return await ApiService.put("cancel_queue_id_card/" + data.id, data);
  };

  const cancel_Queue_registration = async (data) => {
    return await ApiService.put("cancel_queue_registration/" + data.id, data);
  };




  return {
    getDatas,
    getData,
    getData_registrations,
    getData_registration,
    getData_finish,
    getData_registration_finish,
    cancel_Queue,
    cancel_Queue_registration
   
  };
}
