<script setup>
import { onMounted, inject, ref, reactive, computed } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import useApi from "@/service/api/registration";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { th } from "date-fns/locale";
import useSweetalert from "@/service/sweetalert2";

const store = inject("store");
const router = inject("router");
const { getDatas, stoerData } = useApi();
const datas = ref([]);
const dateDisable = ref([0, 1, 2, 3, 4, 5, 6]);
let isLoading = ref(true);
let color = ref(process.env.VUE_APP_COLOR);
const { Sconfirm, SconfirmNc } = useSweetalert();
const check_personal_id = ref(false);

const data = Yup.object().shape({
  id_card: Yup.string()
    .required("กรุณากรอกเลขบัตรประชาชน")
    .min(13, "เลขบัตรประชาชนไม่ครบ")
    .max(13, "รูปแบบเลขบัตรประชาชนไม่ถูกต้อง"),
  id_department: Yup.string().required("กรุณาเลือก แผนก"),
  dateST: Yup.date().required("กรุณาเลือก วันที่").typeError("กรุณาเลือก วันที่"),
  dateC: Yup.string().required("กรุณาเลือก ช่วงเวลา"),
});

const data_c = reactive({
  id_department: "",
  dateST: "",
  dateC: "",
  qty: "",
});

onMounted(() => {
  if (!store.getters.getAccessToken.accesstoken) {
    router.push({ name: "Home" });
  }
  getDatas().then((response) => {
    datas.value = response.data.data;
    isLoading.value = false;
  });
});

const back = () => {
  router.push({ name: "Profile" });
};
const getDateC = computed(() => {
  let daa;
  const dadd = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  if (data_c.dateST) {
    let d = new Date(data_c.dateST);
    let dd = datas.value.filter((item) => item.id === data_c.id_department);
    for (const [key, value] of Object.entries(dd[0].time_on_off)) {
      switch (key) {
        case dadd[d.getDay()]:
          daa = value.time;
          break;

        default:
          break;
      }
    }
  }

  return daa.sort();
});

const onSubmit = (values) => {
  checkCard(values.id_card);
  if (check_personal_id.value) {
    Sconfirm("ยืนยัน การจองคิว", "question").then(async (result) => {
      if (result.isConfirmed) {
        isLoading.value = true;
        values.id_line = store.getters.getAccessToken.accesstoken;
        values.qty = data_c.qty;
        await stoerData(values).then((result) => {
          isLoading.value = false;
          if (result.data.status === "success") {
            SconfirmNc("จองคิวสำเร็จ", "success", "ตกลง").then(() => {
              isLoading.value = false;
              router.push({ name: "History_detail_registration", params: { id: result.data.id } });
            });
          } else {
            SconfirmNc(result.data.message, "error", "ตกลง");
          }
        });
      }
    });
  }
};

function checkCard(id_card) {
  var total = 0;
  var iPID;
  var chk;
  var Validchk;
  iPID = id_card;
  Validchk = iPID.substr(12, 1);
  var j = 0;
  var pidcut;
  for (var n = 0; n < 12; n++) {
    pidcut = parseInt(iPID.substr(j, 1));
    total = total + pidcut * (13 - n);
    j++;
  }
  chk = 11 - (total % 11);

  if (chk == 10) {
    chk = 0;
  } else if (chk == 11) {
    chk = 1;
  }

  if (chk == Validchk) {
    check_personal_id.value = true;
    return true;
  } else {
    SconfirmNc("ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง", "error", "ตกลง");
    return false;
  }
}
const minDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 2);
  return date;
};
const genDate = (e) => {
  dateDisable.value = [0, 1, 2, 3, 4, 5, 6];
  data_c.dateST = "";
  data_c.dateC = "";
  data_c.qty = "";
  if (data_c.id_department) {
    let dd = datas.value.filter((item) => item.id === e);
    data_c.qty = dd[0].qty;
    for (const [key, value] of Object.entries(dd[0].time_on_off)) {
      switch (key) {
        case "monday":
          if (value.status) {
            var index1 = dateDisable.value.indexOf(1);
            if (index1 >= 0) {
              dateDisable.value.splice(index1, 1);
            }
          }

          break;
        case "tuesday":
          if (value.status) {
            var index2 = dateDisable.value.indexOf(2);
            if (index2 >= 0) {
              dateDisable.value.splice(index2, 1);
            }
          }

          break;
        case "wednesday":
          if (value.status) {
            var index3 = dateDisable.value.indexOf(3);
            if (index3 >= 0) {
              dateDisable.value.splice(index3, 1);
            }
          }

          break;
        case "thursday":
          if (value.status) {
            var index4 = dateDisable.value.indexOf(4);
            if (index4 >= 0) {
              dateDisable.value.splice(index4, 1);
            }
          }

          break;
        case "friday":
          if (value.status) {
            var index5 = dateDisable.value.indexOf(5);
            if (index5 >= 0) {
              dateDisable.value.splice(index5, 1);
            }
          }

          break;
        case "saturday":
          if (value.status) {
            var index6 = dateDisable.value.indexOf(6);
            if (index6 >= 0) {
              dateDisable.value.splice(index6, 1);
            }
          }

          break;
        case "sunday":
          if (value.status) {
            var index = dateDisable.value.indexOf(0);
            if (index >= 0) {
              dateDisable.value.splice(index, 1);
            }
          }

          break;

        default:
          break;
      }
    }
  }
};
</script>
<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <div class="row">
      <div class="col-12 f13">
        <div class="card concard card-shadow">
          <div class="card-header white text-center">
            <img src="@/assets/logo.png" style="height: 100px" alt="..." />
            <div class="t1" style="margin: 5px">จองคิวการให้บริการงานทะเบียน</div>
          </div>
          <Form
            @submit="onSubmit"
            :validation-schema="data"
            id="kt_account_profile_details_form"
            ref="formContainer"
            novalidate="novalidate"
          >
            <div class="card-body mb-47 mt-2 white">
              <!--begin::Card body-->
              <div class="card-body">
                <!--begin::Input group-->
                <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label required fw-bold fs-6"
                    >เลขบัตรประชาชน</label
                  >
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field
                      type="number"
                      name="id_card"
                      class="form-control form-control-solid"
                      placeholder="เลขบัตรประชาชน"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="id_card" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->

                <!--begin::Input group-->
                <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label required fw-bold fs-6"
                    >แผนก</label
                  >
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field
                      as="select"
                      name="id_department"
                      class="form-control form-control-solid"
                      v-model="data_c.id_department"
                      @change="genDate(data_c.id_department)"
                    >
                      <option value="" selected disabled>โปรดเลือก</option>
                      <option
                        v-for="department in datas"
                        :key="department.id"
                        :value="department.id"
                      >
                        {{ department.name }}
                      </option>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="id_department" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->

                <div class="row mb-6" v-if="data_c.id_department">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label required fw-bold fs-6"
                    >วันที่</label
                  >
                  <!--end::Label-->
                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field type="hidden" name="dateST" v-model="data_c.dateST" />
                    <Datepicker
                      locale="th"
                      :enableTimePicker="false"
                      placeholder="เลือกวัน"
                      :format-locale="th"
                      cancelText="ยกเลิก"
                      selectText="เลือก"
                      v-model="data_c.dateST"
                      :minDate="minDate()"
                      :disabledWeekDays="dateDisable"
                    >
                    </Datepicker>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="dateST" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>

                <!--begin::Input group-->
                <div class="row mb-6" v-if="data_c.dateST">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label required fw-bold fs-6"
                    >ช่วงเวลา</label
                  >
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field
                      as="select"
                      name="dateC"
                      class="form-control form-control-solid"
                      v-model="data_c.dateC"
                    >
                      <option value="" selected disabled>โปรดเลือก</option>
                      <option v-for="date_c in getDateC" :key="date_c" :value="date_c">
                        {{ date_c }}
                      </option>
                    </Field>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="dateC" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->
              </div>
              <!--end::Card body-->
            </div>
            <div class="card-footer white">
              <div class="d-flex justify-center align-center">
                <div style="width: 100%; padding: 0px 5px">
                  <button type="button" @click="back" class="button btn-back">
                    ย้อนกลับ
                  </button>
                </div>
                <div style="width: 100%; padding: 0px 5px">
                  <button type="submit" style="width: 100%" class="button">จองคิว</button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}
.icong {
  width: 50px;
  padding: 5px;
}
.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}
input[type="radio"] {
  display: none;
}
input:checked + span {
  color: var(--color);
  border: 2px solid var(--color);
}
</style>
